import { regex } from "./index.js"
import receiptProperties from "./receipt.js"

const { paymentMode, paymentDate } = receiptProperties

const voucherProperties = {
    voucherNo: {
        id: 'voucherNo',
        title: "Voucher No.",
        type: 'Number',
        required: true,
    },
    organizationId: {
        id: 'organizationId',
        type: 'Select',
        options: [],
        title: 'Organization',
        required: true
    },
    accountedTo: {
        id: 'accountedTo',
        title: "Accounted To",
        type: 'String',
        required: true,
        regex: regex.name,
    },
    medicineExpiryDate: {
        id: 'medicineExpiryDate',
        title: "Medicine Expiry Date",
        type: 'Date',
        required: true,
        default: new Date(),
    },
    voucherDate: {
        id: "voucherDate",
        title: "Voucher Date",
        type: 'Date',
        required: true,
        default: new Date(),
    },
    amount: {
        id: "amount",
        title: "Amount",
        type: 'Currency',
        required: true,
        regex: regex.number,
    },
    paymentDate,
    paymentMode,
}

export default voucherProperties