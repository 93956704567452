import { lazy, Suspense, useEffect } from "react";

// Router
import {
    BrowserRouter as AppRouter,
    Route,
    Switch,
} from "react-router-dom";

// Routes
import { Routes } from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import FullLayout from "../layout/FullLayout";
import HorizontalLayout from "../layout/HorizontalLayout";

// Components
import HomePage from "../view/home";
import Error404 from "../view/pages/error";
import { useDispatch, useSelector } from "react-redux";
import utils from "../utils";
import { setAuth, setCategory, setCurrentOrganization, setData, setLoading, setOrganizations } from "../utils/reduxStore";
import { getAll,getAllWithPagination, getDocById, getDocsByOrganizationId } from "../utils/helperFunctions";

import jwt from 'jsonwebtoken'


export default function Router() {
    const { role, token } = useSelector(state => state.auth)

    const dispatch = useDispatch()

    const localRole = window.localStorage.getItem('role')

    if (localRole && role !== localRole) {
        const localToken = window.localStorage.getItem('token')
        const localUsername = window.localStorage.getItem('username')
        dispatch(setAuth({ role: localRole, token: localToken, username: localUsername }))
    }

    const getData = async () => {
        dispatch(setLoading(true))
        let param={ page:1,perPage:50,sortProperty:'_id',sortOrder:1}
        if (role === 'operator') {
            const payload = jwt.decode(token)
            param.organizationId =  payload.organizationId
            // get operator's organization
            await getDocById('organization', payload.organizationId).then(doc => {
                window.localStorage.setItem('currentOrganization', JSON.stringify(doc))
                dispatch(setCurrentOrganization(doc))
            }).catch(err => {
                console.log(err)
            })

            // get docs of all pages for current operator's organization (getDocsByOrganizationId)
            utils.users[role].pages.Dashboard.forEach(async page => {
                if(page.title==='Organization'){
                    await getDocsByOrganizationId(page.title, payload.organizationId).then(docs => {
                      dispatch(setData[page.title](docs))
                    }).catch(err => {
                      console.log(err)
                    })
                  }else{
                await getAllWithPagination(page.title,param).then(docs => {
                    dispatch(setData[page.title](docs))
                }).catch(err => {
                    console.log(err)
                })
                }
            })

            // get organizations for createNew and edit dropdowns
            await getAll('organization').then(docs => {
                dispatch(setOrganizations(docs))
            }).catch(err => {
                console.log(err)
            })

        }

        if (role === 'admin') {
            utils.users[role].pages.Dashboard.forEach(async page => {
                if(page.title==='Organization'){
                    await getAll(page.title).then(docs => {
                        dispatch(setData[page.title](docs))
                    }).catch(err => {
                        console.log(err)
                    })
                  }else{
                    await getAllWithPagination(page.title,param).then(docs => {
                        //console.log("page",page,"docs",docs)
                        dispatch(setData[page.title](docs))
                      }).catch(err => {
                        console.log(err)
                      })
                  }
               
            })
        }

        // get Categories
        await getAll('Category').then(doc => {
            dispatch(setCategory(doc))
        }).catch(err => {
            console.log(err)
        })

        dispatch(setLoading(false))
    }

    useEffect(() => {
        if (role) {
            getData()
        }
    }, [role])

    if (role) {

        // Dashboard
        Routes.push({
            path: "/dashboard",
            component: lazy(() => import("../view/pages/dashboard")),
            layout: "HorizontalLayout",
        })
        // Object.keys(utils.users[role].pages).slice(1)
        utils.users[role].pages.Dashboard.forEach(({ title }) => {  // push all pages except dashboard

            // Main pages
            Routes.push(
                {
                    path: "/" + title.toLowerCase(),
                    component: lazy(() => import("../view/pages/" + title.toLowerCase())),
                    layout: "HorizontalLayout",
                }
            )

            // Create New pages
            Routes.push(
                {
                    path: "/create/" + title.toLowerCase(),
                    component: lazy(() => import("../view/pages/" + title.toLowerCase() + '/create')),
                    layout: "HorizontalLayout",
                }
            )

            //Edit pages
            Routes.push(
                {
                    path: "/update/" + title.toLowerCase(),
                    component: lazy(() => import("../view/pages/" + title.toLowerCase() + '/update')),
                    layout: "HorizontalLayout",
                }
            )
        })
    }


    // Default Layout
    const DefaultLayout = null; // FullLayout or VerticalLayout

    // All of the available layouts
    const Layouts = { VerticalLayout, FullLayout, HorizontalLayout };

    // Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];
        if (Routes) {
            // Checks if Route layout or Default layout matches current layout
            Routes.filter(route => (route.layout === layout || DefaultLayout === layout) && (
                LayoutRoutes.push(route),
                LayoutPaths.push(route.path)
            ));
        }

        return { LayoutRoutes, LayoutPaths };
    };

    // Return Route to Render
    const ResolveRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);
            const LayoutTag = Layouts[layout];

            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        render={(props) => {
                                            return (
                                                <Suspense fallback={null}>
                                                    <route.component {...props} />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </LayoutTag>
                </Route>
            );
        });
    };

    return (
        <AppRouter>
            <Switch>
                {ResolveRoutes()}

                {/* Home Page */}
                <Route
                    exact
                    path={'/'}
                    render={() => {
                        return (
                            <Layouts.HorizontalLayout>
                                <HomePage />
                            </Layouts.HorizontalLayout>
                        )
                    }}
                />

                {/* NotFound */}
                <Route path='*'>
                    <Error404 />
                </Route>
            </Switch>
        </AppRouter>
    );
};