import { regex } from "./index.js"
import receiptProperties from "./receipt"

const {
    organizationId,
    address,
    landmark,
    countryStateCity,
    pincode,
    email,
    phone,
    mobile,
    panNo,
    aadhaarNo,
} = receiptProperties

const donorName = {
    id: 'donorName',
    title: 'Donor Name',
    type: 'String',
    required: true,
    regex: regex.name,
}

const donorProperties = {
    donorName,
    organizationId,
    aadhaarNo,
    panNo,
    email,
    mobile,
    phone,
    address,
    landmark,
    countryStateCity,
    pincode,
}

export default donorProperties