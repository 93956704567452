import { createSlice, configureStore } from '@reduxjs/toolkit'

const siteData = createSlice({
    name: 'siteData',
    initialState: {
        auth: {
            token: '',
            username: '',
            role: '',
        },
        getAll: {
            Receipt: [],
            Donor: [],
            Voucher: [],
            Operator: [],
            Organization: [],
            Towards: [],
        },
        getAppliedFilters:[],
        selectedRows: [],
        currentOrganization: {},
        category: {},
        selectedTableRowkeys: [],
        updateDocId: '',
        loading: true,
    },
    reducers: {
        setAuth: (state, action) => {
            state.auth = action.payload
        },
        resetAuth: (state) => {
            state.auth = {
                token: '',
                username: '',
                role: '',
            }
        },
        setReceipts: (state, action) => {
            state.getAll.Receipt = action.payload
        },
        setDonors: (state, action) => {
            state.getAll.Donor = action.payload
        },
        setVouchers: (state, action) => {
            state.getAll.Voucher = action.payload
        },
        setOperators: (state, action) => {
            state.getAll.Operator = action.payload
        },
        setOrganizations: (state, action) => {
            state.getAll.Organization = action.payload
        },
        setTowards: (state, action) => {
            state.getAll.Towards = action.payload
        },
        setSelectedRows: (state, action) => {
            state.selectedRows = action.payload
        },
        setCurrentOrganization: (state, action) => {
            state.currentOrganization = action.payload
        },
        setCategory: (state, action) => {
            state.category = action.payload
        },
        setSelectedTableRowkeys: (state, action) => {
            state.selectedTableRowkeys = action.payload
        },
        setUpdateDocId: (state, action) => {
            state.updateDocId = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAppliedFilters:(state,action)=>{
            state.getAppliedFilters = action.payload
        }
    }
})


export const {
    setAuth,
    resetAuth,
    setAppliedFilters,
    setReceipts,
    setDonors,
    setVouchers,
    setOperators,
    setOrganizations,
    setTowards,
    setSelectedRows,
    setCurrentOrganization,
    setCategory,
    setSelectedTableRowkeys,
    setUpdateDocId,
    setLoading,
} = siteData.actions

export const setData = {
    Receipt: setReceipts,
    Donor: setDonors,
    Voucher: setVouchers,
    Operator: setOperators,
    Organization: setOrganizations,
    Towards:setTowards
}

export const store = configureStore({
    reducer: siteData.reducer
})

export default siteData.reducer