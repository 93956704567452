import { paymentModeOptions } from "./formFeilds"
import{pdfBackgroundImg} from '../assets/pdfBackgroundImg'
import moment from "moment"
const emptyPlaceholder = '-'
const transparentImageUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
const role = window.localStorage.getItem('role')
let docDefination = {
    receiptContent: (doc) => {
        doc.paymentMode = paymentModeOptions[doc.paymentMode].title
        return {
            pageSize: 'A4',
            pageOrientation: 'potrait',
            pageMargins:[35, 100, 15, 105],
            content: [
                {
                    table: {
                        widths: [555],
                        heights: [210],
                        body: [
                            [
                                {
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    table: {
                                        widths: [200, 300],
                                        body: [
                                            [
                                                {
                                                    text: `RECEIPT NO.: ${doc.receiptNo}\n`,
                                                    bold: true
                                                },
                                                {
                                                    text: `Date: ${moment(doc.receiptDate).format('D/MM/YYYY')}`,
                                                    style: {
                                                        alignment: "right"
                                                    }
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Received with thanks from M/s.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.donorName.toUpperCase()}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Aadhaar No.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.aadhaarNo ? String(doc.aadhaarNo).replace(/\d{4}(?=.)/g, '$& ') : emptyPlaceholder}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "PAN No.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.panNo ? doc.panNo : emptyPlaceholder}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Address",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.city.toUpperCase()}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Sum of Rupees",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.paidSumInRs.toLocaleString('en-IN')}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: `By ${doc.paymentMode}`,
                                                    bold: true,
                                                },
                                                {
                                                    text: doc.paymentModeNo ? `: ${doc.paymentMode} ${doc.paymentMode === 'UPI' ? 'ID' : 'No.'} ${doc.paymentModeNo}` : `: ${doc.paymentMode}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Bank Name",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc?.bankName ? doc.bankName : ' -'}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Towards",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.towards}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: `${doc.paidSumInRs.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}`,
                                                    bold: true,
                                                    fontSize: 20,
                                                    border: [true, true, true, true],
                                                },
                                                {
                                                    text: `${doc.paidSumInWords}`,
                                                },
                                            ],
                                        ]
                                    },
                                },
                            ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ],
        }
    },
    receipt: (doc) => {
        doc.paymentMode = paymentModeOptions[doc.paymentMode]?.title || paymentModeOptions[doc.paymentMode]
        return {
            pageSize: 'A4',
            pageOrientation: 'potrait',
            pageMargins: [25, 8, 15, 8],
            images: {
                logoLeft: doc.organization.organizationLogo ? doc.organization.organizationLogo : transparentImageUrl,
                logoRight: doc.organization.organizationLogo ? doc.organization.organizationLogo : transparentImageUrl,
                authorizedSignature: doc.organization.authorizedSignature ? doc.organization.authorizedSignature : transparentImageUrl,
            },
            background: {
                width: [595.28],
                height: [420],
                image: pdfBackgroundImg.img1
            },
            content: [
                // {
                //     text: "Computer generated receipt",
                //     bold: true,
                //     absolutePosition: {
                //         x: 20,
                //         y: 390,
                //     },
                // },
                {
                    image: 'authorizedSignature',
                    width: 75,
                    absolutePosition: {
                        x: 475,
                        y: 315,
                    },
                },
                // {
                //     text: "Authorized Signature\n",
                //     bold: true,
                //     absolutePosition: {
                //         x: 455,
                //         y: 375,
                //     },
                // },
                // {
                //     image: 'logoLeft',
                //     width: 50,
                //     absolutePosition: {
                //         x: 25,
                //         y: 30,
                //     },
                // },
                {
                    image: 'logoRight',
                    width: 50,
                    absolutePosition: {
                        x: 525,
                        y: 30,
                    },
                },
                {
                    table: {
                        widths: [555],
                        heights: [70, 200, 90],
                        body: [
                            // [
                            //     {
                            //         text: [
                            //             `Registration No.: ${doc.organization.registrationNo ? doc.organization.registrationNo : emptyPlaceholder}\n`,
                            //             {
                            //                 text: `${doc.organization.organizationName}\n`,
                            //                 fontSize: 15,
                            //                 bold: true,
                            //                 style: {
                            //                     color: '#2d115c'
                            //                 }
                            //             },
                            //             {
                            //                 text: `${doc.organization.address ? doc.organization.address : emptyPlaceholder}`,
                            //             },
                            //         ],
                            //         fillColor: '#fffad1',
                            //         margin: [0, 5, 0, 5],
                            //         style: {
                            //             alignment: "center"
                            //         }
                            //     },
                            // ],
                            [
                                {
                                    layout: {
                                        defaultBorder: false,
                                    },
                                    table: {
                                        widths: [200, 300],
                                        body: [
                                            [
                                                {
                                                    text: `RECEIPT NO.: ${doc.receiptNo}\n`,
                                                    bold: true
                                                },
                                                {
                                                    text: `Date: ${moment(doc.receiptDate).format('D/MM/YYYY')}`,
                                                    style: {
                                                        alignment: "right"
                                                    }
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Received with thanks from M/s.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.donorName.toUpperCase()}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Aadhaar No.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.aadhaarNo ? String(doc.aadhaarNo).replace(/\d{4}(?=.)/g, '$& ') : emptyPlaceholder}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "PAN No.",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.panNo ? doc.panNo : emptyPlaceholder}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Address",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.city.toUpperCase()}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Sum of Rupees",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.paidSumInRs.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: `By ${doc.paymentMode}`,
                                                    bold: true,
                                                },
                                                {
                                                    text: doc.paymentModeNo ? `: ${doc.paymentMode} ${doc.paymentMode === 'UPI' ? 'ID' : 'No.'} ${doc.paymentModeNo}` : `: ${doc.paymentMode}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Bank Name",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc?.bankName ? doc.bankName : ' -'}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: "Towards",
                                                    bold: true,
                                                },
                                                {
                                                    text: `: ${doc.towards}`,
                                                },
                                            ],
                                            [
                                                {
                                                    text: `${doc.paidSumInRs.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}`,
                                                    bold: true,
                                                    fontSize: 20,
                                                    border: [true, true, true, true],
                                                },
                                                {
                                                    text: `${doc.paidSumInWords}`,
                                                },
                                            ],
                                        ]
                                    },
                                    margin: [0, 100, 0, 5],
                                },
                            ],
                            // [
                            //     {
                            //         text: [
                            //             {
                            //                 text: `For ${doc.organization.organizationName}\n`,
                            //                 style: {
                            //                     color: "#2d115c",
                            //                     alignment: "right"
                            //                 },
                            //             },
                            //             "Subject to realisation of the cheque\n",
                            //             {
                            //                 text: [
                            //                     "Donations to ",
                            //                     {
                            //                         text: `${doc.organization.organizationName}\n`,
                            //                         bold: true,
                            //                         style: {
                            //                             color: '#2d115c',
                            //                         },
                            //                     },
                            //                     "are exempt from Income Tax under 80G,\n"
                            //                 ],
                            //             },
                            //             {
                            //                 text: `Income Tax Order No.: ${doc.organization.incomeTaxOrderNo ? doc.organization.incomeTaxOrderNo : emptyPlaceholder}.\n`,
                            //                 bold: true,
                            //             },
                            //             {
                            //                 text: `PAN No. ${doc.organization.panNo ? doc.organization.panNo : emptyPlaceholder}\n`,
                            //                 fontSize: 16,
                            //                 bold: true,
                            //                 style: {
                            //                     color: "#2d115c"
                            //                 }
                            //             },
                            //         ],
                            //         fillColor: '#fffad1',
                            //     },
                            // ],
                        ],
                    },
                    layout: {
                        defaultBorder: false,
                    }
                },
            ],
        }
    },
    letter: (doc) => {
        return {
            pageSize: 'A4',
           // pageMargins: [0, 30, 0, 0],
            images: {
                authorizedSignature: doc.organization.authorizedSignature ? doc.organization.authorizedSignature : transparentImageUrl,
            },
            background:{
                width:[595.28],
                height:[842],
                image:pdfBackgroundImg.img2
            },
            content: [
                {
                    image: 'authorizedSignature',
                    width: 75,
                    absolutePosition: {
                        x: 40,
                        y: 570,
                    },
                },
                {
                    text: `Date: ${ moment(doc.receiptDate).format('D/MM/YYYY')}`,
                    margin: [0, 120, 0, 20],
                },
                {
                    text: "To,"
                },
                {
                    text: `${doc.donorName.toUpperCase()}`,
                    bold: true,
                },
                {
                    text: `${doc.city}`
                },
                {
                    text: "Sub: Donation receipt along with thanks letter",
                    margin: [0, 20],
                    style: {
                        alignment: "center"
                    }
                },
                {
                    text: "Dear Sir/Madam,",
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                {
                    text: [
                        {
                            text: "    Thanks for donation of ",
                            preserveLeadingSpaces: true,
                        },
                        {
                            text: `${doc.paidSumInRs.toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })} `,
                            bold: true,
                        },
                        {
                            text: "to our prestigious and permanent project "
                        },
                        {
                            text: "SARAS DIALYSIS CENTRE. ",
                            bold: true,
                        },
                        {
                            text: "Grace of God and blessings from you since last more than ten years. We are successfully ruling the Dialysis exclusively for financially affected people from the society at Rs 200/ per dialysis.\n\n"
                        },
                        {
                            text: "    With your blessings and good wishes now we are having eleven Dialysis Machines at our new three storied Maharshinagar, Pune 411037. We are 100% confident that with your gracious donation we will be saving Kidney affected patients. Our present Dialysis Centre is of international standard. We are having well technicians and other staff to look after all the patients. In addition to dialysis at subsidized rate, we are also giving free ice-cream, tea and coffee to the patients.\n\n",
                            preserveLeadingSpaces: true,
                        },
                        {
                            text: "    I am enclosing here with Receipt for donation of ",
                            preserveLeadingSpaces: true,
                        },
                        {
                            text: `${doc.paidSumInRs.toLocaleString('en-IN', {
                                maximumFractionDigits: 2,
                                style: 'currency',
                                currency: 'INR'
                            })} `,
                            bold: true
                        },
                        {
                            text: "(Rupees) Bearing No. "
                        },
                        {
                            text: `${doc.receiptNo} `,
                            bold: true,
                        },
                        {
                            text: `Dt. ${moment(doc.receiptDate).format('D/MM/YYYY')} No. u/s/ 80G of Income Tax Act, 1961. Your donation is 50% tax benefit and you can avail the same at the time of the returns. Please kindly accept and acknowledge.\n`,
                        },
                        {
                            text: "\n\nWith Regards.\nYours,\n",
                        },
                        {
                            text: "For LIONS CLUB OF POONA \nSARASBAUG CHARITABLE TRUST.\n",
                            bold: true,
                        },
                        {
                            text: "\n\n\n\nLION YOGESH SHAH \nTreasurer",
                            bold: true,
                        }
                    ]
                },
            ]
        }
    },
    voucher: (doc) => {
        doc.paymentMode = paymentModeOptions[doc.paymentMode].title
        return {
            pageSize: 'A5',
            pageOrientation: 'landscape',
            content: [
                {
                    text: `Date: ${new Date(doc.voucherDate).toLocaleDateString()}`,
                    absolutePosition: {
                        x: 445,
                        y: 320,
                    }
                },
                {
                    text: "Receiver's Signature",
                    bold: true,
                    absolutePosition: {
                        x: 445,
                        y: 365,
                    }
                },
                {
                    text: "LIONS CLUB OF POONA SARASBAUG CHARITABLE TRUST",
                    bold: true,
                    fontSize: 16,
                    margin: [5, 5],
                    style: {
                        alignment: "center",
                    }
                },
                {
                    text: "VOUCHER",
                    fontSize: 14,
                    style: {
                        alignment: "center",
                    }
                },
                {
                    table: {
                        widths: [400, 100],
                        heights: [60, 'auto', 90],
                        body: [
                            [
                                {
                                    text: `ACCOUNTED TO ${doc.accountedTo}\n\nMedicine Expiry Date ${new Date(doc.medicineExpiryDate).toLocaleDateString()}`
                                },
                                {
                                    text: `No.: ${doc.voucherNo} \n\nDate: ${new Date(doc.voucherDate).toLocaleDateString()}`
                                }
                            ],
                            [
                                {
                                    text: "DESCRIPTION",
                                    style: {
                                        alignment: "center"
                                    }
                                },
                                {
                                    text: "AMOUNT",
                                    style: {
                                        alignment: "center"
                                    }
                                }
                            ],
                            [
                                {
                                    text: "",
                                    rowSpan: 2,
                                },
                                {
                                    text: "",
                                }
                            ],
                            [
                                {
                                    text: ""
                                },
                                {
                                    text: `${doc.amount}`,
                                    style: {
                                        alignment: "right"
                                    }
                                }
                            ],
                        ]
                    }
                },
                {
                    text: "The above mentioned amount is paid to\n\n",
                    margin: [0, 5]
                },
                {
                    text: doc.paymentModeNo ? `${doc.paymentMode} ${doc.paymentMode === 'UPI' ? 'ID' : 'No.'} ${doc.paymentModeNo}` : doc.paymentMode,
                },
                {
                    text: `Bank Name: ${doc?.bankName ? doc.bankName : ' -'}`,
                },
                {
                    text: "\nSanctioned by",
                    bold: true
                },
            ]
        }
    }
}

export default docDefination