import donorProperties from "../formFeilds/donor"
import { countryStateCity } from "../formFeilds/index.js"
import { createDoc, deleteDocs, exportToExcel } from "./index.js"

const createDonor = { ...createDoc, id: createDoc.id + 'Donor', title: createDoc.title + 'Donor' }
const deleteDonors = { ...deleteDocs, id: deleteDocs.id + 'Donors', title: deleteDocs.title + 'Donors' }
const operations = {
    admin: [createDonor, exportToExcel, deleteDonors],
    operator: [createDonor, exportToExcel]
}

const defaultValue = {}
Object.values(donorProperties).forEach(property => {
    if (property.id === 'countryStateCity') {
        countryStateCity.forEach(feild => {
            defaultValue[feild.id] = feild.default
        })
    } else {
        defaultValue[property.id] = property.default
    }
})

const filterIdsToDisplay = ["organizationId", "donorName", "panNo", "aadhaarNo", "panNo", "mobile", "phone", "email", "address",]
const filters = Object.values(donorProperties).filter(property => filterIdsToDisplay.includes(property.id))

const tableColumns = Object.values(donorProperties)

// separate countryStateCity
const countryStateCityIndex = tableColumns.findIndex(tableColumn => tableColumn.id === 'countryStateCity')
if (countryStateCityIndex > -1) {
    tableColumns.splice(countryStateCityIndex, 1, ...countryStateCity)
}

const createNewFeilds = Object.values(donorProperties)
const editFeilds = createNewFeilds

// disable panNo feild
const panNoFeildIndex = editFeilds.findIndex(editFeild => editFeild.id === 'panNo')
editFeilds[panNoFeildIndex] = { ...editFeilds[panNoFeildIndex], disabled: true }

const Donor = {
    filters,
    operations,
    createNewFeilds,
    editFeilds,
    tableColumns,
    defaultValue,
    editLinkColumnId: 'donorName',
}

export default Donor