import { regex, statusOptions } from "./index.js"

const organizationProperties = {
    registrationNo: { id: 'registrationNo', title: 'Registration No', type: 'String' },
    organizationName: { id: 'organizationName', title: 'Organization Name', type: 'String', required: true, regex: regex.name },
    status: { id: 'status', title: 'Status', type: 'Select', options: Object.values(statusOptions), default: "active" },
    panNo: { id: 'panNo', title: 'PAN No', type: 'String', regex: regex.panNo },
    incomeTaxOrderNo: { id: 'incomeTaxOrderNo', title: 'Income Tax Order No', type: 'String' },
    contactPersonName: { id: 'contactPersonName', title: 'Contact Person Name', type: 'String', regex: regex.name },
    email: { id: 'email', title: 'Email', type: 'Email', },
    phone: { id: 'phone', title: 'Phone', type: 'Number', regex: regex.phone },
    address: { id: 'address', title: 'Address', type: 'TextArea', ellipsis: true, },
    countryStateCity: {
        id: 'countryStateCity',
        title: "CountryStateCity",
        type: 'String',
        required: true
    },
    pincode: { id: 'pincode', title: 'Pincode', type: 'Number', regex: regex.pincode },
    openingTotal: { id: 'openingTotal', title: 'Opening Total', type: 'String' },
    organizationLogo: { id: 'organizationLogo', title: 'Organization Logo', type: 'Upload' },
    authorizedSignature: { id: 'authorizedSignature', title: 'Authorized Signature', type: 'Upload' },

}

export default organizationProperties