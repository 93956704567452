import { Modal, notification } from "antd"
import { deleteByIds, isValidRowsForDelete, } from "../helperFunctions"
import { store } from "../reduxStore.js"
import { PlusOutlined, FileExcelOutlined, DeleteOutlined, WarningOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from 'react'
import * as XLSX from 'xlsx'
import admin from "../users/admin.js";
import { statusOptions } from "../formFeilds/index.js";

export const messageIcon = {
  success: <CheckCircleOutlined style={{ color: 'green' }} />,
  warning: <WarningOutlined style={{ color: 'yellow' }} />,
  error: <CloseCircleOutlined style={{ color: 'red' }} />,
}

// common operations
export const exportToExcel = {
  id: 'exportToExcel',
  title: 'Export To Excel',
  icon: <FileExcelOutlined />,
  performOperation: async (page, selectedRows) => {
    const role = window.localStorage.getItem('role')
    //console.log("selectedRows....",selectedRows)
    if (selectedRows.length < 1) {
      notification.warning({ icon: messageIcon.warning, message: `Select at least one ${page.toLowerCase()}` })
      return
    }

    const docs = page==='Organization' ?store.getState().getAll[page] : store.getState().getAll[page].data
    const organizations = store.getState().getAll.Organization
    const selectedDocs = []
    
    let sheetHeaderProperties = admin.pages[page].tableColumns

    docs.forEach(doc => {
      if (selectedRows.includes(doc._id)) {
        const selectedDoc = sheetHeaderProperties.reduce((obj, property) => {
          if (property.id === 'organizationId') {
            obj[property.title] = organizations.find(organization => organization._id === doc.organizationId)?.organizationName
          } else if (property.id.match(/date/ig)) {
            const dateValue = new Date(doc[property.id])
            obj[property.title] = `${dateValue.toLocaleDateString()} ${dateValue.toLocaleTimeString()}`
          } else if (property.id === 'status') {
            obj[property.title] = statusOptions[doc[property.id]].title
          } else {
            obj[property.title] = doc[property.id]
          }
          return obj
        }, {})
        selectedDocs.push(selectedDoc)
      }
    })
    selectedDocs.reverse()

    //xlsx
    const worksheet = XLSX.utils.json_to_sheet(selectedDocs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `${page}s`);
    XLSX.writeFile(workbook, `${page}s.xlsx`);
  },
}


const covertDateFormat =(inputDate)=>{



// Split the input string into date and time parts
const [datePart, timePart] = inputDate.split(' ');

// Split the date part into day, month, and year
const [day, month, year] = datePart.split('/');

// Define an array of month names
const monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

// Create the formatted date string
const formattedDate = `${day}-${monthNames[Number(month) - 1]}-${year}`;
console.log(formattedDate); // Output: "21-Oct-2023"
return formattedDate
}

// common operations
export const exportToTally = {
  id: 'exportToTally',
  title: 'Export To Tally',
  icon: <FileExcelOutlined />,
  performOperation: async (page, selectedRows) => {
    const role = window.localStorage.getItem('role')
    //console.log("selectedRows....",selectedRows)
    if (selectedRows.length < 1) {
      notification.warning({ icon: messageIcon.warning, message: `Select at least one ${page.toLowerCase()}` })
      return
    }

    const docs = page==='Organization' ?store.getState().getAll[page] : store.getState().getAll[page].data
    const organizations = store.getState().getAll.Organization
    const selectedDocs = []
    
    let sheetHeaderProperties = admin.pages[page].tableColumns

    docs.forEach(doc => {
      if (selectedRows.includes(doc._id)) {
        const selectedDoc = sheetHeaderProperties.reduce((obj, property) => {
          if (property.id === 'organizationId') {
            obj[property.title] = organizations.find(organization => organization._id === doc.organizationId)?.organizationName
          } else if (property.id.match(/date/ig)) {
            const dateValue = new Date(doc[property.id])
            obj[property.title] = `${dateValue.toLocaleDateString()} ${dateValue.toLocaleTimeString()}`
          } else if (property.id === 'status') {
            obj[property.title] = statusOptions[doc[property.id]].title
          } else {
            obj[property.title] = doc[property.id]
          }
          return obj
        }, {})
        selectedDocs.push(selectedDoc)
      }
    })
    console.log("selectedDocs", selectedDocs);
// Your data
let filterSelectedDocs = selectedDocs.map((data,index) => {
 
  return {
    
    'Receipt Number': data['Receipt Number'],
    'Date': covertDateFormat(data['Receipt Date']),
    'Donor Name': data['Donor Name'],
    'Address': data['Address'],
    'Email': data['Email'],
    'Phone No': data['Phone'],
    'Payment Mode': data['Payment Mode'],
    'Amount': data['Paid Sum In Rs.'],
    'Cheque/DD/ CreditCard/Ref. No.': '',
    'Bank Name': data['Bank Name'],
    'Towards': data['Towards'],
  };
});

let newArr= [{ 'Receipt Number': '',
'Date': '',
'Donor Name':'' ,
'Address': '',
'Email': '',
'Phone No':'' ,
'Payment Mode': '',
'Amount': '',
'Cheque/DD/ CreditCard/Ref. No.': '',
'Bank Name': '',
'Towards': '',},{ 'Receipt Number': '',
'Date': '',
'Donor Name':'' ,
'Address': '',
'Email': '',
'Phone No':'' ,
'Payment Mode': '',
'Amount': '',
'Cheque/DD/ CreditCard/Ref. No.': '',
'Bank Name': '',
'Towards': '',}]

filterSelectedDocs.map((nData)=>{
  newArr.push(nData)
})

// Create a new workbook
const workbook = XLSX.utils.book_new();

// Create a worksheet
const worksheet = XLSX.utils.json_to_sheet(newArr);
worksheet['A1'] = { v: '', t: 's' };
worksheet['A2'] = { v: 'Donation Receipts', t: 's' };
worksheet['I2'] = { v: '', t: 's' };
worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },{ s: { r: 1, c: 0 }, e: { r: 1, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 1, c: 10 } }]; // Merge cells

// Define the headers
worksheet['A3'] = { v: 'Receipt Number' };
worksheet['B3'] = { v: 'Date' };
worksheet['C3'] = { v: 'Donor Name' };
worksheet['D3'] = { v: 'Address' };
worksheet['E3'] = { v: 'Email' };
worksheet['F3'] = { v: 'Phone No' };
worksheet['G3'] = { v: 'Payment Mode' };
worksheet['H3'] = { v: 'Amount' };
worksheet['I3'] = { v: 'Cheque/DD/ CreditCard/Ref. No.' };
worksheet['J3'] = { v: 'Bank Name' };
worksheet['K3'] = { v: 'Towards' };


// Add the worksheet to the workbook
XLSX.utils.book_append_sheet(workbook, worksheet, `${page}s`);

// Save the workbook to a file
XLSX.writeFile(workbook, `${page}s.xlsx`);
  },
}

export const deleteDocs = {
  id: 'delete',
  title: 'Delete ',
  icon: <DeleteOutlined />,
  performOperation: async (page, tableRowKeys) => {
    if (!tableRowKeys.length) {
      notification.warning({ icon: messageIcon.warning, message: `Select at least one ${page.toLowerCase()}` })
      return
    } else if ((page === 'Receipt' || page === 'Voucher') && !isValidRowsForDelete([...tableRowKeys])) {
      notification.warning({ icon: messageIcon.warning, message: `You can only delete when ${page.toLowerCase()}s are selected in descending order` })
      return
    }

    const selectedRows = store.getState().selectedRows
    return new Promise((resolve) => {
      const handleModalOk = async () => {
        if(page === 'Towards'){
          try {
            const doc = await deleteByIds(page.toLowerCase(), selectedRows)
            if(doc) {
                  const { deletedCount } = doc
                  notification.success({ icon: messageIcon.success, message: `${deletedCount > 1 ? `${deletedCount} ${page}` : page} deleted successfully` })
                  resolve(deletedCount)
                } else {
                  notification.warning({ icon: messageIcon.warning, message: doc.deletedCount })
                  resolve(false)
                }
          } catch (err) {
            console.log(err)
            resolve(false)
          }
        }else{
        try {
          const doc = await deleteByIds(page.toLowerCase(), selectedRows)
          if (doc) {
            if (doc.deletedCount > 0) {
              const { deletedCount } = doc
              notification.success({ icon: messageIcon.success, message: `${deletedCount > 1 ? `${deletedCount} ${page}s` : page} deleted successfully` })
              resolve(deletedCount)
            } else {
              notification.warning({ icon: messageIcon.warning, message: doc.deletedCount })
              resolve(false)
            }
          }
        } catch (err) {
          console.log(err)
          resolve(false)
        }}
      }

      const handleModalCancel = () => {
        resolve(false)
      }

      Modal.confirm({
        title: `Are you sure you want to delete selected ${page.toLowerCase()}${tableRowKeys.length > 1 ? '' : ''}?`,
        icon: <WarningOutlined style={{ fontSize: '36px', color: 'yellow' }} />,
        okText: "Delete",
        cancelText: "Cancel",
        onOk: handleModalOk,
        onCancel: handleModalCancel,
      });
    })
  },
}

export const createDoc = {
  id: 'create',
  title: 'Create ',
  performOperation: (page, selectedRows, history) => {
    history.push(`/create/${page.toLowerCase()}`)
  },
  icon: <PlusOutlined />
}