import organizationProperties from "../formFeilds/organization"
import { countryStateCity } from "../formFeilds/index.js"
import { createDoc, deleteDocs, exportToExcel } from "./index.js";

const createOrganization = { ...createDoc, id: createDoc.id + 'Organization', title: createDoc.title + 'Organization' }
const deleteOrganizations = { ...deleteDocs, id: deleteDocs.id + 'Organizations', title: deleteDocs.title + 'Organizations' }
const operations = {
    admin: [createOrganization, exportToExcel, deleteOrganizations],
    operator: [createOrganization, exportToExcel]
}

const defaultValue = {}
Object.values(organizationProperties).forEach(property => {
    if (property.id === 'countryStateCity') {
        countryStateCity.forEach(feild => {
            defaultValue[feild.id] = feild.default
        })
    } else {
        defaultValue[property.id] = property.default
    }
})

const filterIdsToDisplay = ["organizationName", "registrationNo", "countryStateCity", "pincode", "panNo", "status"]
const filters = Object.values(organizationProperties).filter(property => filterIdsToDisplay.includes(property.id))

const tableColumnsToExclude = ["organizationLogo", "authorizedSignature"]
const tableColumns = Object.values(organizationProperties).filter(property => !tableColumnsToExclude.includes(property.id))

// separate countryStateCity
const countryStateCityIndex = tableColumns.findIndex(tableColumn => tableColumn.id === 'countryStateCity')
if (countryStateCityIndex > -1) {
    tableColumns.splice(countryStateCityIndex, 1, ...countryStateCity)
}

const createNewFeilds = [...Object.values(organizationProperties)]
const editFeilds = createNewFeilds

const Organization = {
    filters,
    operations,
    createNewFeilds,
    editFeilds,
    tableColumns,
    defaultValue,
    editLinkColumnId: 'registrationNo',
}

export default Organization