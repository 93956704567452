import { lazy } from "react";

const PagesRoutes = [
  // PAGES
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/login/admin",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import("../../view/pages/authentication/reset-password")
    ),
    layout: "FullLayout",
  },
  {
    path: "/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
  },
];

export default PagesRoutes;