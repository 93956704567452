import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import Title from "antd/lib/typography/Title";

export default function MenuLogo({ onClose, onlyLogo = false }) {
  const { currentOrganization } = useSelector(state => state)
  const role = window.localStorage.getItem("role")

  return (
    <Row gutter={6}>
      <Link to="/" className="da-d-flex da-align-items-center" onClick={onClose}>
        <Col>
          {
            role === "admin"
              ? <Row justify="space-around" align="middle">
                <Col>
                  <Title level={4}>Admin Portal</Title>
                </Col>
              </Row>
              : currentOrganization?.organizationLogo
                ? <img className="da-logo" src={currentOrganization.organizationLogo} alt="logo" />
                : <></>
          }
        </Col>
        {!onlyLogo && (
          <Col>
            <Title level={4}>
              {currentOrganization?.organizationName}
            </Title>
          </Col>
        )}
      </Link>
    </Row>
  );
}
