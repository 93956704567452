import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import axios from 'axios';
import { TweenOneGroup } from 'rc-tween-one';
import React, { useEffect, useRef, useState } from 'react';
import config from '../../../configs';
import { setCategory, store } from '../../../utils/reduxStore';

const ManageCategories = () => {
    const receiptCategories = store.getState().category.receipt
    const dispatch = store.dispatch
    const [tags, setTags] = useState([]);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
        }

        setInputVisible(false);
        setInputValue('');
    };

    const updateCategory = async (tags) => {
        await axios.put(`${config.endpoint}/category`, { receipt: tags }).then(res => {
            // console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, []);

    useEffect(() => {
        setTags(receiptCategories)
    }, [receiptCategories])

    useEffect(() => {
        dispatch(setCategory({ receipt: receiptCategories }))
        updateCategory()
    }, [tags])

    const forMap = (tag) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e) => {
                    e.preventDefault();
                    handleClose(tag);
                }}
            >
                {tag}
            </Tag>
        );
        return (
            <span
                key={tag}
                style={{
                    display: 'inline-block',
                }}
            >
                {tagElem}
            </span>
        );
    };

    const tagChild = tags.map(forMap);
    return (
        <>
            <div
                style={{
                    marginBottom: 16,
                }}
            >
                <TweenOneGroup
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                    }}
                    onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                            e.target.style = 'display: inline-block';
                        }
                    }}
                    leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                    }}
                    appear={false}
                >
                    {tagChild}
                </TweenOneGroup>
            </div>
            {inputVisible && (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag onClick={showInput} className="site-tag-plus">
                    <PlusOutlined />  New Category
                </Tag>
            )}
        </>
    );
};

export default ManageCategories;