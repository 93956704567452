import { Col, Layout, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"
import utpl from "../../../assets/images/logo/utpl.png";

const { Footer } = Layout;


export default function MenuFooter() {
  const organizationName = useSelector(state => state.currentOrganization?.organizationName)
  const username = useSelector(state => state.auth?.username)

  return (
    <Footer className="da-bg-color-black-10">
      <Row
        className="da-sidebar-footer da-pb-24 da-px-24"
        align="middle"
        justify="space-between"
      >
        <Col>
          <div>
            {username !== 'admin' ? username : ''}
            <Typography.Title level={5}>{organizationName}</Typography.Title>
          </div>
        </Col>

        <Col>
          <Row align="middle" justify="center">
            <img className="da-logo" src={utpl} alt="logo" />

            <div>
              <span className="da-d-block da-text-color-black-100 da-p1-body">
                Designed and Developed by
              </span>

              <Link
                to={{ pathname: "https://www.upperthrust.com/" }}
                target="_blank"
                className="da-badge-text"
              >
                <Typography.Title level={5}>UPPERTHRUST TECHNOLOGIES PVT. LTD.</Typography.Title>
              </Link>
            </div>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};