import { notification } from "antd"
import moment from "moment"
import voucherProperties from "../formFeilds/voucher"
import { handlePrintPdf } from "../helperFunctions"
import docDefination from "../docDefination"
import config from "../../configs"
import axios from "axios"
import { PrinterOutlined } from '@ant-design/icons';
import React from 'react'
import { createDoc, deleteDocs, exportToExcel } from "./index.js"
import { store } from "../reduxStore"

// Voucher Operations
const createVoucher = { ...createDoc, id: createDoc.id + 'Voucher', title: createDoc.title + 'Voucher' }
const printVoucher = {
    id: 'printVoucher',
    title: "Print Voucher",
    icon: <PrinterOutlined />,
    performOperation: async (page, selectedRows) => {
        if (selectedRows.length < 1) {
            notification.warning({ message: 'Select a voucher to print' })
            return
        } else if (selectedRows.length > 1) {
            notification.warning({ message: 'Select only one voucher to print' })
            return
        }

        const reduxState = store.getState()
        const voucher = reduxState.getAll[page].data[reduxState.selectedTableRowkeys[0]]
        const dd = docDefination.voucher({ ...voucher })
        handlePrintPdf(dd)
    },
}

const deleteVouchers = { ...deleteDocs, id: deleteDocs.id + 'Vouchers', title: deleteDocs.title + 'Vouchers' }
const operations = {
    admin: [createVoucher, printVoucher, exportToExcel, deleteVouchers],
    operator: [createVoucher, printVoucher, exportToExcel]
}

const defaultValue = {}
Object.values(voucherProperties).forEach(property => {
    if (property.id.match(/date/ig)) {
        defaultValue[property.id] = moment(property.default)
    } else {
        defaultValue[property.id] = property.default
    }
})

const rangedFilterIdsToDisplay = ["voucherNo", "voucherDate", "paymentDate", "amount", "medicineExp",]
const rangedFilters = Object.values(voucherProperties).filter(property => rangedFilterIdsToDisplay.includes(property.id))

const filterIdsToDisplay = ["organizationId", "accountedTo", "paymentMode"]
const filters = Object.values(voucherProperties).filter(property => filterIdsToDisplay.includes(property.id))

const tableColumns = Object.values(voucherProperties)

const createNewFeilds = Object.values(voucherProperties).filter(voucherProperty => voucherProperty.id !== 'voucherNo')
const editFeilds = createNewFeilds

const Voucher = {
    rangedFilters,
    filters,
    operations,
    createNewFeilds,
    editFeilds,
    tableColumns,
    defaultValue,
    editLinkColumnId: 'voucherNo',
}

export default Voucher