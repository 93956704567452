import theme from './themeConfig';

const config = {
  title: 'Saras Dialysis',
  endpoint: '/api',
  //endpoint: 'http://localhost:8000/api',
  responsiveBreakpoint: 700,
  sendEmailLimit: 300,
  admin: {
    username: 'admin',
  },
};

export default config;
export { theme };
