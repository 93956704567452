import { Link, useHistory } from "react-router-dom";

import { Menu, Dropdown, Col, Avatar, notification } from "antd";
import {
  InfoSquare,
  Logout,
} from "react-iconly";

import { useDispatch } from "react-redux";
import { UserOutlined } from '@ant-design/icons';
import { logout } from "../../../utils/helperFunctions";
import { resetAuth, setCurrentOrganization } from "../../../utils/reduxStore";
import { messageIcon } from "../../../utils/pages";

export default function HeaderUser() {
  const history = useHistory()
  const dispatch = useDispatch()

  const role = window.localStorage.getItem('role')

  const handleLogout = (e) => {
    logout()
    dispatch(resetAuth())
    dispatch(setCurrentOrganization({}))
    notification.success({ icon: messageIcon.error, message: "Logged out successfully" })
    history.push(role === 'admin' ? '/login/admin' : '/login')
    window.location.reload()
  }

  const menu = (
    <Menu>
      <Menu.Item
        icon={
          <InfoSquare
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={16}
          />
        }
      >
        <Link to="/reset-password">
          Change Password
        </Link>
      </Menu.Item>

      <Menu.Item
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={16}
          />
        }
        onClick={handleLogout}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
        <Avatar size="large" icon={<UserOutlined />} />
      </Col>
    </Dropdown>
  );
};
