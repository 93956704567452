import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts"
import admin from './users/admin'
import operator from './users/operator'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const utils = {
    // pages: ['Dashboard', 'Receipt', 'Organization', 'Operator', 'Donor', 'Voucher'],
    users: {
        admin,
        operator
    },
}

export default utils