import { paymentModeOptions, regex } from "./index.js"


const receiptProperties = {
    receiptNo: {
        id: 'receiptNo',
        title: 'Receipt Number',
        type: 'Number',
        required: true
    },
    organizationId: {
        id: 'organizationId',
        type: 'Select',
        options: [],
        title: 'Organization',
        required: true
    },
    receiptDate: {
        id: 'receiptDate',
        title: 'Receipt Date',
        type: 'Date',
        default: new Date(),
        required: true
    },
    donorName: {
        id: 'donorName',
        title: 'Donor Name',
        type: 'AutoComplete',
        required: true,
        options: [],
        hasFeedback: true,
        regex: regex.name
    },
    aadhaarNo: {
        id: 'aadhaarNo',
        title: 'Aadhaar No.',
        type: 'Number',
        regex: regex.aadhaarNo
    },
    panNo: {
        id: 'panNo',
        title: 'PAN No.',
        type: 'String',
        regex: regex.panNo,
    },
    email: {
        id: 'email',
        title: 'Email',
        type: 'email',
    },
    mobile: {
        id: 'mobile',
        title: 'Mobile',
        type: 'Number',
        regex: regex.mobile,
    },
    phone: {
        id: 'phone',
        title: 'Phone',
        type: 'Number',
        regex: regex.phone,
    },
    address: {
        id: 'address',
        title: 'Address',
        type: 'TextArea',
        required: true,
        ellipsis: true,
    },
    landmark: {
        id: 'landmark',
        title: 'Landmark',
        type: 'String',
    },
    countryStateCity: {
        id: 'countryStateCity',
        title: 'CountryStateCity',
        type: 'SearchSelect',
        required: true
    },
    pincode: {
        id: 'pincode',
        title: 'Pincode',
        type: 'Number',
        required: false,
        regex: regex.pincode,
    },
    towards: {
        id: 'towards',
        title: 'Towards',
        type: 'Select',
        options: [],
        required: true,
    },
    forOfficeReference: {
        id: 'forOfficeReference',
        title: 'For Office Reference',
        type: 'String',
    },
    paidSumInRs: {
        id: 'paidSumInRs',
        title: 'Paid Sum In Rs.',
        type: 'Currency',
        required: true,
        regex: regex.number,
        className: 'column-money',
    },
    paidSumInWords: {
        id: 'paidSumInWords',
        title: 'Paid Sum In Words',
        type: 'Text',
        disabled: true,
    },
    paymentDate: {
        id: 'paymentDate',
        title: 'Payment Date',
        type: 'Date',
        required: true,
        default: new Date(),
    },
    paymentMode: {
        id: 'paymentMode',
        title: 'Payment Mode',
        type: 'Select',
        options: Object.values(paymentModeOptions),
        default: 'cash',
        required: true,
    },
}

export default receiptProperties