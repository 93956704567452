import React from "react";

import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import jwt from 'jsonwebtoken'
import { getAll,getAllWithPagination, getDocById, getDocsByOrganizationId, getDocsByOrganizationIdPagination } from "../../utils/helperFunctions";
import { setCurrentOrganization, setData, setOrganizations } from "../../utils/reduxStore";
import utils from "../../utils";
import { useEffect } from "react";

export default function Home(props) {
  const { role, token } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const getData = async () => {

  let param={ page:1,perPage:50,sortProperty:'_id',sortOrder:1}
    if (role === 'operator') {
      const payload = jwt.decode(token)
      param.organizationId =  payload.organizationId
      // get operator's organization
      await getDocById('organization', payload.organizationId).then(doc => {
        window.localStorage.setItem('currentOrganization', JSON.stringify(doc))
        dispatch(setCurrentOrganization(doc))
      }).catch(err => {
        console.log(err)
      })

      // get docs of all pages for current operator's organization (getDocsByOrganizationId)
      utils.users[role].pages.Dashboard.forEach(async page => {
        if(page.title==='Organization'){
        await getDocsByOrganizationId(page, payload.organizationId).then(docs => {
          dispatch(setData[page](docs))
        }).catch(err => {
          console.log(err)
        })
      }else{
        await getAllWithPagination(page.title,param).then(docs => {
          dispatch(setData[page](docs))
        }).catch(err => {
          console.log(err)
        })
      }
      })

      // get organizations for createNew and edit dropdowns
      await getAll('organization').then(docs => {
        dispatch(setOrganizations(docs))
      }).catch(err => {
        console.log(err)
      })

    }

    if (role === 'admin') {
      utils.users[role].pages.Dashboard.forEach(async page => {
        if(page.title==='Organization'){
          await getAll(page).then(docs => {
            // console.log("page",page)
            dispatch(setData[page](docs))
          }).catch(err => {
            console.log(err)
          })
        }else{
        await getAllWithPagination(page.title,param).then(docs => {
          // console.log("page",page,"docs",docs)
          dispatch(setData[page.title](docs))
        }).catch(err => {
          console.log(err)
        })
      }
      })
    }
  }

  useEffect(() => {
    if (role) {
      getData()
    }
  }, [role])

  return (
    role
      ? <Redirect to='/dashboard' />
      : <Redirect to='/login' />
  );
}
