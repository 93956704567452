import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import { Provider } from "react-redux";
import { store } from "./utils/reduxStore";
import Router from "./router/Router";

import './styles.css'

ReactDOM.render(
  <Suspense fallback="loading">
    <BrowserRouter >
      <Provider store={store}>
        <Router />
      </Provider>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);