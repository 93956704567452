import { Col, Layout, Row, Typography } from "antd";
import MenuHeader from "./components/header";
import { useState,useEffect } from "react";
import Sidebar from "./components/menu/Sidebar";
import MenuFooter from "./components/footer";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

export default function HorizontalLayout({ children }) {
    const [showSidebar, setShowSidebar] = useState(false);
    const loading = useSelector(state => state.loading)
    const { role } = useSelector(state => state.auth)
    const history  = useHistory()
    useEffect(() => {
        if(!role){
            history.push('/login')
        }
    }, [role]);
    return (
        <div>
            <Layout className="da-app-layout" style={{ width: '100vw', }}>
                {showSidebar && <Sidebar visible={showSidebar} setVisible={setShowSidebar} />}

                <Layout style={{ width: '100%', height: '100%' }}>
                    <MenuHeader setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
                    <Content style={{ margin: '100px 10px 20px 10px', }} className="da-content-main">
                        {
                            loading
                                ? <Row justify="middle" className="align-center-loading">
                                    <Col className="text-align-centre" span={24}>
                                        <Loading3QuartersOutlined
                                            spin
                                            style={{ fontSize: '4em', color: '#008AD8' }}
                                        />
                                    </Col>
                                    <Col span={24} className="text-align-centre">
                                        <Typography.Title level={3}>Loading...</Typography.Title>
                                    </Col>
                                </Row>
                                : children
                        }
                    </Content>
                    <MenuFooter />
                </Layout>
            </Layout>
        </div>
    )
};