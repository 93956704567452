import Receipt from "../pages/receipt";
import Donor from "../pages/donor";
import Voucher from "../pages/voucher";

import {
  FileTextOutlined,
  GiftOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import React from "react";

const operator = {
  pages: {
    Dashboard: [
      { title: "Receipt", icon: <FileTextOutlined style={{ fontSize: '20px' }} /> },
      { title: "Donor", icon: <TeamOutlined style={{ fontSize: '20px' }} /> },
      { title: "Voucher", icon: <GiftOutlined style={{ fontSize: '20px' }} /> },
    ],
    // pass organization id along with formdata in these pages
    Receipt,
    Donor,
    Voucher,
  },
};

export default operator;
