import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import config from "../configs";
import docDefination from "./docDefination";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const handleDownloadPdf = (page) => {
    let dd = docDefination.receipt
    pdfMake.createPdf(dd).download(`Saras Dialysis - ${page} ${new Date().toLocaleDateString()}`);
}

export const handlePrintPdf = (dd) => {
    pdfMake.createPdf(dd).print()
}

export const getPdfFile = (dd) => {
    return pdfMake.createPdfKitDocument(dd);
}

export const getBase64Receipt = (dd) => {
    let base64Receipt = ''
    const pdfDocGenerator = pdfMake.createPdf(dd);
    pdfDocGenerator.getBase64((data) => {
        base64Receipt = data
    });
    return base64Receipt
}

export const persistLogin = (token, role, username) => {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('role', role);
    window.localStorage.setItem('username', username);
};

export const logout = () => {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('role')
    window.localStorage.removeItem('username')
    window.localStorage.removeItem('currentOrganization')
}

export const setDefaultLogin = () => {
    window.localStorage.setItem('role', 'operator');
}


export const validateEmail = (email) => {
    const regex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    return regex.test(email)
}

export const validatePincode = (pincode) => {
    const regex = new RegExp(/\d{6}/g)
    return regex.test(pincode)
}

export const validateMobile = (mobile) => {
    const regex = new RegExp(/\d{10}/g)
    return regex.test(mobile)
}

export const validatePhone = (phone) => {
    const regex = new RegExp(/\d{6,10}/g)
    return regex.test(phone)
}

export const validatePan = (pan) => {
    const regex = new RegExp(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)
    return regex.test(pan)
}

export const numToWords = (num) => {
    var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if ((num = num.toString()).length > 9) return 'Overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) : '';
    return str + 'Rupees Only';
}

export const isValidRowsForDelete = (keys) => {
    keys.sort((a, b) => a - b)
    // console.log(keys)
    if (keys[0] === 0) {
        if (keys.every((key, index) => key === index)) {
            return true
        }
    }

    return false
}

// Create New
export const createNew = async (page, formData) => {
    if (page) {
        try {
            const response = await axios.post(`${config.endpoint}/${page}`, formData)
            if (response) {
                return response
            }
        } catch (err) {
            console.log(`helperfunctions createNew ${page}`, err)
        }
    }
}
export const updateCategory = async (tags) => {
    await axios.put(`${config.endpoint}/category`, { receipt: tags }).then(res => {
        console.log("res",res)
        return res
    }).catch(err => {
        console.log(err)
    })
}
// Get All
export const getAll = async (page) => {
    if (page) {
        try {
            const response = await axios.get(`${config.endpoint}/${page.toLowerCase()}`)

            if (response) {
                return response.data.doc
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const getCountByFinancialYear = async (receiptDate) => {
    // console.log("receiptDate..........", receiptDate)
    try {
        const response = await axios.get(`${config.endpoint}/receipt/receiptCountByYear`,
            receiptDate
        )
        if (response) {
            // console.log("countsss..........", response)
        }
    } catch (err) {
        console.log(err)
    }
}



export const getDateMonthYear = (v) => {
    const dateVal = new Date(v).getDate();
    const monthVal = new Date(v).getMonth() + 1;
    const yearVal = new Date(v).getFullYear();

    return { dateVal, monthVal, yearVal }
}

// Function to check if the current date falls within the financial year
export const isInFinancialYear = (currentDate) => {
    const { start, end } = getFinancialYearDates(currentDate);

    // Check if the current date is greater than or equal to the start date
    // and less than or equal to the end date of the financial year
    return currentDate >= start && currentDate <= end;
}

function getFinancialYearDates(currentDate) {
    const currentYear = currentDate.getFullYear();
    const nextYear = currentYear + 1;

    // Financial year starts from April 1st of the current year
    const financialYearStart = new Date(currentYear, 3, 1);

    // Financial year ends on March 31st of the next year
    const financialYearEnd = new Date(nextYear, 2, 31);

    return {
        start: financialYearStart,
        end: financialYearEnd
    };
}

export const getAllWithPagination = async (page, params) => {
    console.log("params................................", params,page)
    if (page) {
        try {
            if(page == 'Receipt' && params?.selectedAll===undefined) {
                params.selectedAll = false
            }else if (page == 'Receipt' && (params.financialYear==undefined ||params.financialYear==null) && (params.receiptDateFrom===undefined ||params.receiptDateTo===undefined)) {
                // Calculate the current date
                const currentDate = new Date();
                const isInFY = isInFinancialYear(currentDate);

                // Get the current year
                const currentYear = isInFY?currentDate.getFullYear():currentDate.getFullYear()-1
                const currentFY = currentYear + '-' + (currentYear + 1)
                let financialYear = []
                currentFY?.split("-")?.map((val, index) => {
                    console.log(index, getDateMonthYear(new Date(val)))
                    let { yearVal } = getDateMonthYear(new Date(val))
                    if (index == 0) {
                        financialYear[index] = +yearVal + "-" + 4 + "-" + 1
                    } else {
                        financialYear[index] = yearVal + "-" + 3 + "-" + 31
                    }
                    
                })
                params = { ...params, financialYear }
                // console.log("params financialYear",params);
            }

            const response = await axios.get(`${config.endpoint}/${page.toLowerCase()}`, {
                "params": params
            })

            if (response && params.selectedAll==true) {
                // console.log("response && params.selectedAll true...",response, params.selectedAll);
                return response.data.doc
            }else if (response) {
                // console.log("response && params.selectedAll false...",response , params.selectedAll);
               
                return response.data.doc[0]
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const getDocByIds = async (page, ids) => {
    if (page) {
        try {
            const res = await axios.post(`${config.endpoint}/${page.toLowerCase()}/getByIds`, { ids })
            const docs = res.data.doc
            if (docs.length) {
                return docs
            } else {
                throw new Error('Empty array returned from backend')
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const deleteByIds = async (page, ids) => {
    if (page) {
        if (page === "towards") {
            let data = await getAll("Category")
            let excludeArr = []
            ids.map((id) => {
                excludeArr.push(data.receipt[id])
            })
            let newArr = data.receipt.filter((item) => !excludeArr.includes(item))
            let response = await axios.put(`${config.endpoint}/category`, { receipt: newArr }).then(res => {
                return res.data.doc
            }).catch(err => {
                console.log(err)
            })
            return response
        } else {
            const axiosConfig = { data: { ids } }
            try {
                const res = await axios.delete(`${config.endpoint}/${page.toLowerCase()}`, axiosConfig)
                return res.data.doc
            } catch (err) {
                console.log(err)
                return err
            }
        }
    }
}

export const getDocsByOrganizationId = async (page, organizationId) => {
    if (page) {
        try {
            const res = await axios.get(`${config.endpoint}/${page.toLowerCase()}/organization/${organizationId}`)
            if (res) {
                return res.data.doc
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const getDocsByOrganizationIdPagination = async (page, organizationId, param) => {
    if (page) {
        try {
            const res = await axios.get(`${config.endpoint}/${page.toLowerCase()}/organization/${organizationId}`, {
                param
            })
            if (res) {
                return res.data.doc[0]
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const getDocById = async (page, id) => {
    if (page) {
        try {
            const res = await axios.get(`${config.endpoint}/${page.toLowerCase()}/${id}`)
            if (res) {
                return res.data.doc
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const editById = async (page, id, values) => {
    if (page) {
        try {
            const res = await axios.put(`${config.endpoint}/${page.toLowerCase()}/${id}`, values)
            if (res) {
                return res
            }
        } catch (err) {
            console.log(err)
        }
    }
}