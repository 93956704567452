import { Modal, notification } from "antd"
import axios from "axios"
import moment from "moment"
import config from "../../configs/index.js"
import docDefination from "../docDefination.js"
import { countryStateCity, paymentModeExtraFeild } from "../formFeilds/index.js"
import receiptProperties from "../formFeilds/receipt"
import { handlePrintPdf } from "../helperFunctions"
import { setCategory, store } from "../reduxStore.js"
import { PrinterOutlined, MailOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React from 'react'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ManageCategories from "../../view/pages/Main/ManageCategories.js"
import { createDoc, deleteDocs, exportToExcel,exportToTally, messageIcon } from "./index.js"

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { endpoint, sendEmailLimit } = config

const role = window.localStorage.getItem('role')
// Receipt Operations
const createReceipt = { ...createDoc, id: createDoc.id + 'Receipt', title: createDoc.title + 'Receipt' }

const printReceipt = {
    id: 'printReceipt',
    title: 'Print Receipt',
    icon: <PrinterOutlined />,
    performOperation: async (page, selectedRows) => {
        if (selectedRows.length < 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select a receipt to print' })
            return
        } else if (selectedRows.length > 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select only one receipt to print' })
            return
        }

        const reduxState = store.getState()
        const receipt = reduxState.getAll[page].data[reduxState.selectedTableRowkeys[0]]
        const organization = reduxState.auth.role === 'admin' ? reduxState.getAll.Organization.find(org => org._id === receipt.organizationId) : reduxState.currentOrganization
        const dd = docDefination.receipt({ ...receipt, organization })
        handlePrintPdf(dd)
    },
}

const printReceiptContent = {
    id: 'printReceiptContent',
    title: 'Print Receipt Content',
    icon: <PrinterOutlined />,
    performOperation: async (page, selectedRows) => {
        if (selectedRows.length < 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select a receipt to print' })
            return
        } else if (selectedRows.length > 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select only one receipt to print' })
            return
        }

        const reduxState = store.getState()
        const receipt = reduxState.getAll[page].data[reduxState.selectedTableRowkeys[0]]
        const dd = docDefination.receiptContent({ ...receipt })
        handlePrintPdf(dd)
    },
}

const printLetter = {
    id: 'printLetter',
    title: 'Print Letter',
    icon: <PrinterOutlined />,
    performOperation: async (page, selectedRows) => {
        if (selectedRows.length < 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select a receipt to print letter' })
            return
        } else if (selectedRows.length > 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select only one receipt to print letter' })
            return
        }

        const reduxState = store.getState()
        const receipt = reduxState.getAll[page].data[reduxState.selectedTableRowkeys[0]]
        const organization = reduxState.auth.role === 'admin' ? reduxState.getAll.Organization.find(org => org._id === receipt.organizationId) : reduxState.currentOrganization
        const dd = docDefination.letter({ ...receipt, organization })
        handlePrintPdf(dd)
    },
}

const sendReceiptAndLetterByEmail = {
    id: 'sendReceiptAndLetterByEmail',
    title: 'Send Receipt and Letter by Email',
    icon: <MailOutlined />,
    performOperation: async (page, selectedRows) => {
        if (selectedRows.length < 1) {
            notification.warning({ icon: messageIcon.warning, message: 'Select at least one receipt' })
            return
        } else if (selectedRows.length > sendEmailLimit) {
            notification.warning({ icon: messageIcon.warning, message: `Select between 1 to ${sendEmailLimit} receipts to send email` })
            return
        }

        const postData = { docIds: selectedRows }
        await axios.post(`${endpoint}/receipt/email`, postData).then(res => {
            if (res.data.message === 'Success') {
                notification.success({ icon: messageIcon.success, message: res.data.doc })
            } else {
                notification.error({ icon: messageIcon.error, message: res.data.message })
            }
        }).catch(err => {
            console.log(err)
            notification.error({ icon: messageIcon.error, message: err.response?.data?.error ? err.response.data.error : err.message })
        })
    },
}

const deleteReceipts = { ...deleteDocs, id: deleteDocs.id + 'Receipts', title: deleteDocs.title + 'Receipts' }

const manageCategories = {
    id: 'manageCategories',
    title: 'Manage Categories',
    icon: <UnorderedListOutlined />,
    performOperation: async () => {
        const handleModalOk = async () => {
            try {
                const res = await axios.get(`${endpoint}/category`)
                const doc = res.data.doc
                store.dispatch(setCategory(doc))
            } catch (err) {
                console.log(err)
            }
        }

        Modal.confirm({
            title: 'Manage Categories',
            content: <ManageCategories />,
            okText: `Save`,
            cancelText: "Cancel",
            icon: "",
            onOk: handleModalOk,
        });
    },
}

const operations = {
    admin: [createReceipt, printReceipt, printReceiptContent, printLetter, exportToExcel,exportToTally, sendReceiptAndLetterByEmail, deleteReceipts, manageCategories],
    operator: [createReceipt, printReceipt, printReceiptContent, printLetter, exportToExcel,exportToTally, sendReceiptAndLetterByEmail]
}

const defaultValue = {}
Object.values(receiptProperties).forEach(property => {
    if (property.id.match(/date/ig)) {
        defaultValue[property.id] = moment(property.default)
    } else if (property.id === 'countryStateCity') {
        countryStateCity.forEach(feild => {
            defaultValue[feild.id] = feild.default
        })
    } else {
        defaultValue[property.id] = property.default
    }
})
const rangedFilterIdsToDisplay = ['financialYear',"receiptNo", "receiptDate", "paymentDate", "paymentAmount", "paidSumInRs",]
const rangedFilters = Object.values(receiptProperties).filter(property => rangedFilterIdsToDisplay.includes(property.id))

const filterIdsToDisplay = ["organizationId", "countryStateCity", "donorName", "panNo", "aadhaarNo", "phone", "email", "mobile", "paymentMode", "towards"]
const filters = Object.values(receiptProperties).filter(property => filterIdsToDisplay.includes(property.id))
filters.push({
    "id": "financialYear",
    "title": "Financial Year",
    "type": "Year",
    "required": true,
    //"default": new Date()
})

const tableColumns = Object.values(receiptProperties).filter(property => property.id !== "paidSumInWords")

//add extra feilds column
tableColumns.push(...Object.values(paymentModeExtraFeild))

// separate countryStateCity
const countryStateCityIndex = tableColumns.findIndex(tableColumn => tableColumn.id === 'countryStateCity')
if (countryStateCityIndex > -1) {
    tableColumns.splice(countryStateCityIndex, 1, ...countryStateCity)
}

const createNewFeilds = Object.values(receiptProperties).filter(receiptProperty => receiptProperty.id !== 'receiptNo')
const editFeilds = createNewFeilds

// disable panNo feild
const panNoFeildIndex = editFeilds.findIndex(editFeild => editFeild.id === 'panNo')
editFeilds[panNoFeildIndex] = { ...editFeilds[panNoFeildIndex], disabled: true }

const Receipt = {
    rangedFilters,
    filters,
    operations,
    createNewFeilds,
    editFeilds,
    tableColumns,
    defaultValue,
    editLinkColumnId: 'receiptNo',
}

export default Receipt