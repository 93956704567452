import { Menu } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import config from "../../../configs";
import utils from "../../../utils";

export default function TabMenu() {
    const [current, setCurrent] = useState("");

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/")

    useEffect(() => {
        const pathnameSplit = location.pathname.split('/')
        const currentPage = pathnameSplit[pathnameSplit.length - 1]
        setCurrent(currentPage)
    }, [location])

    const role = useSelector(state => state.auth.role)

    const tabs = []

    if (role) {
        Object.keys(utils.users[role].pages).forEach(page => {
            tabs.push({
                id: page.toLowerCase(),
                title: page,
                navLink: "/" + page.toLowerCase(),
            })
        })
    }

    const handleClick = (e) => {
        setCurrent(e.key);
    };

    return (
        window.innerWidth > config.responsiveBreakpoint
            ? <Menu
                onClick={handleClick}
                selectedKeys={[current]}
                mode="horizontal"
            >
                {
                    tabs.map(tab => {
                        const itemNavLink = tab.navLink.split("/");

                        return (
                            <Menu.Item
                                key={tab.id}
                                className={
                                    tab.id === "profile"
                                        ? splitLocation[splitLocation.length - 3] +
                                            "/" +
                                            splitLocation[splitLocation.length - 2] ===
                                            itemNavLink[itemNavLink.length - 3] +
                                            "/" +
                                            itemNavLink[itemNavLink.length - 2]
                                            ? "ant-menu-item-selected"
                                            : "ant-menu-item-selected-in-active"
                                        : splitLocation[splitLocation.length - 2] +
                                            "/" +
                                            splitLocation[splitLocation.length - 1] ===
                                            itemNavLink[itemNavLink.length - 2] +
                                            "/" +
                                            itemNavLink[itemNavLink.length - 1]
                                            ? "ant-menu-item-selected"
                                            : "ant-menu-item-selected-in-active"
                                }
                            >
                                <Link to={tab.navLink}>{tab.title}</Link>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
            : <></>
    )
}