import { statusOptions } from "./index.js"

const operatorProperties = {
    operatorName: {
        id: 'username',
        title: 'Username',
        type: 'String',
        required: true
    },
    organizationId: {
        id: 'organizationId',
        type: 'Select',
        options: [],
        title: 'Organization',
        required: true
    },
    status: {
        id: 'status',
        title: 'Status',
        type: 'Select',
        options: Object.values(statusOptions),
        default: 'active',
        required: true
    },
    password: {
        id: 'password',
        title: 'Password',
        type: 'String',
        required: true
    },
}

export default operatorProperties