import operatorProperties from "../formFeilds/operator"
import { createDoc, deleteDocs, exportToExcel } from "./index.js";

const createOperator = { ...createDoc, id: createDoc.id + 'Operator', title: createDoc.title + 'Operator' }
const deleteOperators = { ...deleteDocs, id: deleteDocs.id + 'Operators', title: deleteDocs.title + 'Operators' }
const operations = {
    admin: [createOperator, exportToExcel, deleteOperators],
    operator: [createOperator, exportToExcel]
}

const defaultValue = {}
Object.values(operatorProperties).forEach(property => {
    defaultValue[property.id] = property.default
})

const filterIdsToDisplay = ["organizationId", "username", "status", "organization"]
const filters = Object.values(operatorProperties).filter(property => filterIdsToDisplay.includes(property.id))

const tableColumns = Object.values(operatorProperties).filter(property => property.id !== 'password')

const createNewFeilds = [...Object.values(operatorProperties)]
const editFeilds = [...createNewFeilds]

// remove required for password feild for editFeilds
const passwordFeildIndex = editFeilds.findIndex(editFeild => editFeild.id === 'password')
editFeilds[passwordFeildIndex] = { ...editFeilds[passwordFeildIndex], required: false }

const Operator = {
    filters,
    operations,
    createNewFeilds,
    editFeilds,
    tableColumns,
    defaultValue,
    editLinkColumnId: 'username',
}

export default Operator