import { notification } from "antd"
import bankNames from "./bankNames"

export const countryStateCity = [
    {
        title: 'Country',
        id: 'country',
        default: 'IN',
    },
    {
        title: 'State',
        id: 'state',
        default: 'MH',
    },
    {
        title: 'City',
        id: 'city',
        default: 'Pune',
    },
]

export const paymentModeOptions = {
    cash: { id: 'cash', title: 'Cash' },
    netbanking: { id: 'netbanking', title: 'Net Banking' },
    cheque: { id: 'cheque', title: 'Cheque' },
    upi: { id: 'upi', title: 'UPI' },
    dd: { id: 'dd', title: 'DD' },
}

export const isValidAntdValidationType = (type) => {
    const validAntdValidationTypes = ['string', 'number', 'email']
    return !!(validAntdValidationTypes.includes(type));
}

export const regex = {
    panNo: {
        pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/g,
        message: 'Please enter a valid PAN No.'
    },
    phone: {
        pattern: /^\d{6,8}$/g,
        message: 'Please enter a valid 6-8 digit phone number',
    },
    mobile: {
        pattern: /^\d{10}$/g,
        message: 'Please enter a valid 10 digit mobile number',
    },
    pincode: {
        pattern: /^\d{6}$/g,
        message: 'Please enter a valid 6 digit pincode'
    },
    name: {
        pattern: /^[a-zA-Z\s,.'-]+$/ug,
        message: 'Please enter a valid name'
    },
    number: {
        pattern: /^\d+$/g,
        message: 'Please enter a valid number'
    },
    aadhaarNo: {
        pattern: /^[2-9]{1}\d{11}$/g,
        message: "Please enter a valid Aadhaar No.",
    },
    card: {
        pattern: /^\d{12,19}$/g,
        message: "Please enter a valid a card number"
    },
    cheque: {
        pattern: /^\d{6}$/g,
        message: "Please enter a valid cheque number",
    },
    dd: {
        pattern: /^\d{6}$/g,
        message: "Please enter a valid DD number",
    }
}

export const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
        notification.error({ message: 'You can only upload JPG/PNG file!' });
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
        notification.error({ message: 'Image must smaller than 2MB!' });
    }

    return isJpgOrPng && isLt2M;
};


export const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
        onSuccess("ok");
    }, 0);
};

export const validateMessages = {
    required: '${label} is required!',
    types: {
        email: 'Please enter a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

export const statusOptions = { active: { id: 'active', title: 'Active' }, inactive: { id: 'inactive', title: 'Inactive' } }

export const paymentModeExtraFeild = {
    paymentModeNo: {
        id: 'paymentModeNo',
        title: "Payment Mode No.",
        type: 'String',
    },
    bankName: {
        id: "bankName",
        title: "Bank Name",
        type: 'AutoComplete',
        options: bankNames,
        regex: { ...regex.name, message: "Please enter a valid bank name" }
    }
}