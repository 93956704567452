import React from "react";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill } from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import TabMenu from "../menu/TabMenu";
import MenuLogo from "../menu/logo";
import config from "../../../configs";

const { Header } = Layout;

export default function MenuHeader({ setShowSidebar }) {

  // Mobile Sidebar
  const showDrawer = () => {
    setShowSidebar(true);
  };

  return (
    <Header
      style={{
        position: "fixed",
        zIndex: 1,
        width: "100%",
        height: "5em",
      }}
    >
      <Row
        className="da-w-100 da-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col span={window.innerWidth < config.responsiveBreakpoint ? 8 : 10}>
          <MenuLogo onlyLogo={window.innerWidth < config.responsiveBreakpoint ? true : false} />
        </Col>

        <Col style={{ height: "100%" }} span={window.innerWidth < config.responsiveBreakpoint ? 16 : 14}>

          <Row align="middle" style={{ height: "100%" }} justify="end">

            <Col style={{ height: "100%" }} className='da-header-menus' span={22}>
              <TabMenu />
            </Col>

            {/* Responsive Burger Menu   */}
            <Col className="da-mobile-sidebar-button da-mr-24">
              <Button
                className="da-mobile-sidebar-button"
                type="text"
                onClick={showDrawer}
                icon={
                  <RiMenuFill
                    size={24}
                    className="remix-icon da-text-color-black-80"
                  />
                }
              />
            </Col>

            <Col>
              <HeaderUser />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
}
