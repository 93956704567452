import towardsProperties from "../formFeilds/towards"
import { createDoc, deleteDocs, exportToExcel } from "./index.js";

const createTowards = { ...createDoc}
const deleteTowards = { ...deleteDocs}
const tableColumns = Object.values(towardsProperties)
const operations = {
    admin: [createTowards, deleteTowards],
    operator: [createTowards]
}
const createNewFeilds = Object.values(towardsProperties)
const editFeilds = createNewFeilds

const Towards = {
    createTowards,
    operations,
    tableColumns,
    createNewFeilds,
    editFeilds
}
export default Towards