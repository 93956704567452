import Receipt from "../pages/receipt";
import Donor from "../pages/donor";
import Voucher from "../pages/voucher";
import Operator from "../pages/operator";
import Organization from "../pages/organization";
import Towards from "../pages/towards";
import {
  FileTextOutlined,
  GiftOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import React from "react";

const admin = {
  pages: {
    Dashboard: [
      { title: "Receipt", icon: <FileTextOutlined style={{ fontSize: '20px' }} /> },
      { title: "Donor", icon: <TeamOutlined style={{ fontSize: '20px' }} /> },
      { title: "Voucher", icon: <GiftOutlined style={{ fontSize: '20px' }} /> },
      { title: "Operator", icon: <UsergroupAddOutlined style={{ fontSize: '20px' }} /> },
      { title: "Organization", icon: <ShopOutlined style={{ fontSize: '20px' }} /> },
      { title: "Towards", icon: <ShopOutlined style={{ fontSize: '20px' }} /> }
    ],
    Receipt,
    Donor,
    Voucher,
    Operator,
    Organization,
    Towards
  },
};

export default admin;
